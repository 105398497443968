import { createBrowserRouter } from "react-router-dom";
import auth from "../pages/auth";
import Home from "../pages/Home/Home";
import MainLayout from "../pages/MainLayout/MainLayout";
import VehiclePerformance from "../pages/VehiclePerformance/VehiclePerformance";
import VehicleDiagnostics from "../pages/VehicleDiagnostics/VehicleDiagnostics";
import Hierarchy from "../pages/Hierarchy/Hierarchy";
import AccountSettings from "../pages/Settings/Settings";
import Account from "../pages/Accounts/Account";
import DownloadData from "../pages/DownloadData/DownLoadData";
import DtcPattern from "../pages/DtcPatternPage/DtcPattern";
import DtcDetail from "../pages/DtcDetail/DtcDetail";
import DSMStrategyPage from "../pages/DSMStrategy/DSMStrategyPage";

const router = createBrowserRouter([
  { path: "/login", element: <auth.Login />, errorElement: <></> },
  {
    path: "/",
    element: <auth.Protected />,
    children: [
      { path: "logout", element: <auth.Logout /> },
      {
        path: "",
        element: <MainLayout />,
        children: [
          { path: "dashboard", element: <Home /> },
          { path: "vehicle-performance", element: <VehiclePerformance /> },
          { path: "vehicle-diagnostics", element: <VehicleDiagnostics /> },
          { path: "hierarchy-data", element: <Hierarchy /> },
          { path: "download-data", element: <DownloadData /> },
          { path: "settings", element: <AccountSettings /> },
          { path: "account", element: <Account /> },
          {path:"dsmstrategy", element:<DSMStrategyPage />}
          // Ensure that DtcPattern renders correctly
          
        ],
      },
      { path: "dtcpattern", element: <DtcPattern/> },
      {path:"dtcdetail", element:<DtcDetail/>}
    ],
  },
]);

export default router;
