import React, { useState, useEffect } from "react";
import {
  Drawer,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  ThemeProvider,
  BoxProps,
} from "@mui/material";
import { Outlet, NavLink, useNavigate, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import SmlIcon from "../../assets/png/sml-warroom.png";
import theme from "../../../src/theme";
import DashboardIcon from "../../assets/DrawerMenuIcons/Zones.svg";
import VehicleDiagnostic from "../../assets/wrench.svg";
import Hierarachy from "../../assets/hierarchy.svg";
import Download from "../../assets/download.svg";
import VehiclePerformance from "../../assets/DrawerMenuIcons/Truck.svg";
import Account from "../../assets/smlIcons/PerformanceIcons/User.svg";
import Setting from "../../assets/smlIcons/PerformanceIcons/settings.svg"
import DSM from "../../assets/DSM.svg"

const MainLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [location.pathname, navigate]);
  

  const listItemButtonStyle = (selected: boolean) => ({
    padding: 2,
    color: "#000",
    "&.Mui-selected": {
      color: `${theme.palette.error.main}`,
      backgroundColor: "#F6F5F4",
      "&:hover": {
        backgroundColor: "#F6F5F4",
      },
    },
    ...(drawerOpen || selected
      ? {}
      : {
        color: selected ? theme.palette.error.main : "#000",
      }),
  });

  const verticalRedLine = {
    width: "5px",
    height: "100%",
    backgroundColor: theme.palette.error.main,
    borderRadius: "10px",
  };

  const getSVGComponent = (svgPath: string) => {
    return (props: BoxProps) => (
      <Box component="img" height={"36px"} width={"36px"} src={svgPath} {...props} />
    );
  };

  function isCurrentPath(path: string): boolean {
    return !!(path && path !== "/" && location.pathname.startsWith(path));
  }


  const DashboardSVG = getSVGComponent(DashboardIcon);
  const HierarachySVG = getSVGComponent(Hierarachy);
  const VehicleDiagnosticsSVG = getSVGComponent(VehicleDiagnostic);
  const DownloadSVG = getSVGComponent(Download);
  const VehiclePerformanceSVG = getSVGComponent(VehiclePerformance);
  const AccountSVG = getSVGComponent(Account);
  const SettingsSVG = getSVGComponent(Setting);
  const DSMSVG = getSVGComponent(DSM)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Drawer
        anchor="left"
        variant="permanent"
        open={drawerOpen}
        sx={{
          width: drawerOpen ? "16%" : "72px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerOpen ? "16%" : "72px",
            boxSizing: "border-box",
            transition: "width 0.3s",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between", // Ensures toggle button, main list, and bottom items are spaced properly
            height: "100vh",
            borderTopRightRadius: "16px",
            borderBottomRightRadius: "16px",
            overflow:"hidden"
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: drawerOpen ? "flex-end" : "center",
            padding: 2,
            backgroundColor: "#E82428",
          }}
        >
          <IconButton onClick={toggleDrawer}>
            {drawerOpen ? (
              <MenuIcon sx={{ color: "#fff" }} />
            ) : (
              <img src={SmlIcon} alt="SML Logo" width="32px" />
            )}
          </IconButton>
        </Box>
        {drawerOpen && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 2, // Add margin bottom to create space between the logo and the list
            }}
          >
            <img src={SmlIcon} alt="Another Image" width="100%" />
          </Box>
        )}
        <List
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            padding: 0,
            marginTop: drawerOpen ? 3 : 0, // Add margin top to move the list down
          }}
        >
          <ListItem
            button
            component={NavLink}
            to="/dashboard"
            sx={listItemButtonStyle(isCurrentPath("/dashboard"))}
            selected={isCurrentPath("/dashboard")}
          >
            <ListItemIcon>
              <DashboardSVG
                sx={{
                  color: isCurrentPath("/dashboard") ? theme.palette.error.main : "#000",
                }}
              />
            </ListItemIcon>
            {drawerOpen && (
              <>
                <ListItemText
                  primary="Dashboard"
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "1.25rem",
                      lineHeight: "1.5",
                      fontWeight: isCurrentPath("/dashboard") ? "500" : "400",
                    },
                  }}
                />
                {isCurrentPath("/dashboard") && (
                  <Box
                    sx={{
                      ...verticalRedLine,
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                  />
                )}
              </>
            )}
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/vehicle-diagnostics"
            sx={listItemButtonStyle(isCurrentPath("/vehicle-diagnostics"))}
            selected={isCurrentPath("/vehicle-diagnostics")}
          >
            <ListItemIcon>
              <VehicleDiagnosticsSVG
                sx={{
                  color: isCurrentPath("/vehicle-diagnostics") ? theme.palette.error.main : "#000",
                }}
              />
            </ListItemIcon>
            {drawerOpen && (
              <>
                <ListItemText
                  primary="Vehicle Diagnostics"
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "1.25rem",
                      fontWeight: isCurrentPath("/vehicle-diagnostics") ? "500" : "400",
                    },
                  }}
                />
                {isCurrentPath("/vehicle-diagnostics") && (
                  <Box
                    sx={{
                      ...verticalRedLine,
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                  />
                )}
              </>
            )}
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/vehicle-performance"
            sx={listItemButtonStyle(isCurrentPath("/vehicle-performance"))}
            selected={isCurrentPath("/vehicle-performance")}
          >
            <ListItemIcon>
              <VehiclePerformanceSVG
                sx={{
                  color: isCurrentPath("/vehicle-performance") ? theme.palette.error.main : "#000",
                }}
              />
            </ListItemIcon>
            {drawerOpen && (
              <>
                <ListItemText
                  primary="Vehicle Performance"
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "1.25rem",
                      fontWeight: isCurrentPath("/vehicle-performance") ? "500" : "400",
                    },
                  }}
                />
                {isCurrentPath("/vehicle-performance") && (
                  <Box
                    sx={{
                      ...verticalRedLine,
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                  />
                )}
              </>
            )}
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/hierarchy-data"
            sx={listItemButtonStyle(isCurrentPath("/hierarchy-data"))}
            selected={isCurrentPath("/hierarchy-data")}
          >
            <ListItemIcon>
              <HierarachySVG
                sx={{
                  color: isCurrentPath("/hierarchy-data") ? theme.palette.error.main : "#000",
                }}
              />
            </ListItemIcon>
            {drawerOpen && (
              <>
                <ListItemText
                  primary="Hierarchy Data"
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "1.25rem",
                      fontWeight: isCurrentPath("/hierarchy-data") ? "500" : "400",
                    },
                  }}
                />
                {isCurrentPath("/hierarchy-data") && (
                  <Box
                    sx={{
                      ...verticalRedLine,
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                  />
                )}
              </>
            )}
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/download-data"
            sx={listItemButtonStyle(isCurrentPath("/download-data"))}
            selected={isCurrentPath("/download-data")}
          >
            <ListItemIcon>
              <DownloadSVG
                sx={{
                  color: isCurrentPath("/download-data") ? theme.palette.error.main : "#000",
                }}
              />
            </ListItemIcon>
            {drawerOpen && (
              <>
                <ListItemText
                  primary="Download Data"
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "1.25rem",
                      fontWeight: isCurrentPath("/download-data") ? "500" : "400",
                    },
                  }}
                />
                {isCurrentPath("/download-data") && (
                  <Box
                    sx={{
                      ...verticalRedLine,
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                  />
                )}
              </>
            )}
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/dsmstrategy"
            sx={listItemButtonStyle(isCurrentPath("/dsmstrategy"))}
            selected={isCurrentPath("/dsmstrategy")}
          >
            <ListItemIcon>
              <DSMSVG
                sx={{
                  color: isCurrentPath("/download-data") ? theme.palette.error.main : "#000",
                }}
              />
            </ListItemIcon>
            {drawerOpen && (
              <>
                <ListItemText
                  primary="Diagnostic Service Manual"
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "1.25rem",
                      fontWeight: isCurrentPath("/dsmstrategy") ? "500" : "400",
                    },
                  }}
                />
                {isCurrentPath("/dsmstrategy") && (
                  <Box
                    sx={{
                      ...verticalRedLine,
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                  />
                )}
              </>
            )}
          </ListItem>
        </List>
        <Box
          sx={{
            paddingBottom: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <List>
            <ListItem
              button
              component={NavLink}
              to="/account"
              sx={listItemButtonStyle(isCurrentPath("/account"))}
              selected={isCurrentPath("/account")}
            >
              <ListItemIcon>
                <AccountSVG sx={{
                  color: isCurrentPath("/download-data") ? theme.palette.error.main : "#000",
                }} />

              </ListItemIcon>
              {drawerOpen && (
                <>
                  <ListItemText
                    primary="Account"
                    primaryTypographyProps={{
                      sx: {
                        fontSize: "1.25rem",
                        fontWeight: isCurrentPath("/account") ? "500" : "400",
                      },
                    }}
                  />
                  {isCurrentPath("/account") && (
                    <Box
                      sx={{
                        ...verticalRedLine,
                        position: "absolute",
                        right: 0,
                        top: 0,
                      }}
                    />
                  )}
                </>
              )}
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/settings"
              sx={listItemButtonStyle(isCurrentPath("/settings"))}
              selected={isCurrentPath("/settings")}
            >
              <ListItemIcon>
                <SettingsSVG sx={{
                  color: isCurrentPath("/download-data") ? theme.palette.error.main : "#000",
                }} />

              </ListItemIcon>
              {drawerOpen && (
                <>
                  <ListItemText
                    primary="Settings"
                    primaryTypographyProps={{
                      sx: {
                        fontSize: "1.25rem",
                        fontWeight: isCurrentPath("/settings") ? "500" : "400",
                      },
                    }}
                  />
                  {isCurrentPath("/settings") && (
                    <Box
                      sx={{
                        ...verticalRedLine,
                        position: "absolute",
                        right: 0,
                        top: 0,
                      }}
                    />
                  )}
                </>
              )}
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: "16px",
          height: "100vh",
          backgroundColor: "#F6F5F4",
          transition: "margin-left 0.3s",
          marginLeft: drawerOpen ? "16%" : "72px",
        }}
      >
        <Outlet />
      </Box>
    </ThemeProvider>
  );
};

export default MainLayout;
