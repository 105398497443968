import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { VehicleFaultCode } from "../../types";

interface FaultCodeHistoryTableProps {
  faultCodeHistory: VehicleFaultCode[];
}

const FaultCodeHistoryTable: React.FC<FaultCodeHistoryTableProps> = ({
  faultCodeHistory,
}) => {

  // Utility function to handle newlines
  const formatTextWithLineBreaks = (text: string) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <Paper sx={{ width: "100%", overflow: "auto", marginTop: 2 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Serial No</TableCell>
            <TableCell>DFC Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Major Component</TableCell>
            <TableCell>Sub Component</TableCell>
            <TableCell>Type of Error</TableCell>
            <TableCell>DTCO (Scantool)</TableCell>
            <TableCell>Fault Type (Hex)</TableCell>
            <TableCell>DTCSAE (Meterset updated)</TableCell>
            <TableCell>Cause</TableCell>
            <TableCell>Remedy</TableCell>
            <TableCell>Healing Conditions</TableCell>
            <TableCell>Lamp Strategy (MIL)</TableCell>
            <TableCell>Heal Trigger</TableCell>
            <TableCell>MIL</TableCell>
            <TableCell>SVS</TableCell>
            <TableCell>AWL</TableCell>
            <TableCell>Inducement</TableCell>
            <TableCell>Torque Limitation</TableCell>
            <TableCell>Reaction</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {faultCodeHistory.map((fault, index) => (
            <TableRow key={index}>
              <TableCell>{index+1}</TableCell>
              <TableCell>{fault.dfc_name}</TableCell>
              <TableCell>{fault.description}</TableCell>
              <TableCell>{fault.major_component}</TableCell>
              <TableCell>{fault.sub_component}</TableCell>
              <TableCell>{fault.type_of_error}</TableCell>
              <TableCell>{fault.dtco_scantool}</TableCell>
              <TableCell>{fault.fault_type_hex}</TableCell>
              <TableCell>{fault.dtcsae_meterset_updated}</TableCell>
              <TableCell>{formatTextWithLineBreaks(fault.cause)}</TableCell>
              <TableCell>{formatTextWithLineBreaks(fault.remedy)}</TableCell>
              <TableCell>{formatTextWithLineBreaks(fault.healing_conditions!)}</TableCell>
              <TableCell>{fault.lamp_startegy_mil}</TableCell>
              <TableCell>{fault.heal_trigger}</TableCell>
              <TableCell>{fault.mil}</TableCell>
              <TableCell>{fault.svs}</TableCell>
              <TableCell>{fault.awl}</TableCell>
              <TableCell>{fault.inducement}</TableCell>
              <TableCell>{fault.torque_limitation}</TableCell>
              <TableCell>{fault.reaction}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default FaultCodeHistoryTable;
